import React from 'react';
import Seo from './Seo/Seo';
import { v4 as uuidv4 } from 'uuid';
import {Layout} from './Layout';
import {ServicePastileButton} from './Primitives';
import {ServicePastile} from './index';
import {graphql} from 'gatsby';
import Img from 'gatsby-image';

export const query = graphql`
query BlogPostQuery($id: String) {
  mdx(id: {eq: $id}) {
    slug
    frontmatter {
      contentTitle
      content
      offer
      slug
      title
      featuredImage {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
}
`;

const Template = ({data}) => {
  const { title, contentTitle,  content, featuredImage, offer } = data.mdx.frontmatter;
  return (
    <Layout>
      <Seo title={title} />
      <div className="max-w-7xl h-screen-1/2 mx-auto px-2 md:px-6 md:px-0 md:py-8 flex flex-col items-center">

        <div className="flex flex-col-reverse md:flex-row items-center">
          <div className="w-full md:w-4/6 h-full mt-8 md:mt-0 md:px-4 flex flex-col">
            <h1 className="text-center md:text-left text-3xl mb-4 md:mb-0">{title}</h1>
            <p className="text-md md:text-lg text-justify mt-4">{content}</p>
          </div>
          <div className="w-full md:w-2/6 h-full flex align-center items-center px-4">
            <Img fluid={featuredImage.childImageSharp.fluid} alt={title} className="object-contain w-full"/>
          </div>
        </div>

        <div className="w-full flex flex-col items-center justify-center mt-10">
          <h2 className="text-2xl mt-6">{contentTitle}</h2>
          <div className="flex flex-col md:flex-row mt-8 items-center justify-center md:justify-between w-full">
            {
              offer.length > 0 && JSON.parse(offer).map(off => 
                <ServicePastile key={uuidv4()} offer={off}/>  
              )
            }
          </div>
          <div className="mt-10 w-full h-auto md:h-12 flex flex-col-reverse md:flex-row items-center justify-between">
            <span className="w-3/4 md:w-5/12 smt-4 md:mt-0 flex flex-col md:flex-row justify-between">
              <ServicePastileButton title="Skontaktuj się z nami" link="/kontakt" />
              <ServicePastileButton title="Sprawdź pełną ofertę" link="/oferta" />
            </span>
            <h3 className="text-lg text-center">Zapytaj o ofertę i uzyskaj odpowiedź już dzisiaj!</h3>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Template;